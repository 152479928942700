import cm from 'classnames'
import { useRecoilValue } from 'recoil'
import { showSide } from 'src/store'

const Index = ({ children, className, needHidden, top = 0 }) => {
  const show = useRecoilValue(showSide)
  return (
    <div className={cm('responsive', className)} style={{ marginTop: top }}>
      <div className={show ? 'responsive-left' : ''}></div>
      <div className={cm('responsive-center', needHidden ? 'overflow-hidden' : '')}>{children}</div>
      <div className={show ? 'responsive-right' : ''}></div>
    </div>
  )
}

export default Index
