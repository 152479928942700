import styles from './index.module.scss'
import cm from 'classnames'
import Image from '@c/image'
import Button from '../button'

const contactArr = [
  {
    aria: 'telegram',
    url: 'https://t.me/+CKkE1hC7_3dlYTk1',
    img: '/api/images/footer/call1'
  }
  // {
  //   aria: 'facebook',
  //   url: 'https://www.facebook.com/profile.php?id=100088822460082',
  //   img: '/api/images/footer/call2'
  // },
  // {
  //   aria: 'youtube',
  //   url: 'https://www.youtube.com/channel/UChHQWBxTmexSJ0z_S78TY-w',
  //   img: '/api/images/footer/call3'
  // },
  // {
  //   aria: 'tiktok',
  //   url: 'https://www.tiktok.com/@hlovetv',
  //   img: '/api/images/footer/call4'
  // },
  // {
  //   aria: 'twiter',
  //   url: 'https://twitter.com/hlove_tv',
  //   img: '/api/images/footer/call5'
  // }
]

const Index = ({ top = 70 }) => {
  return (
    <footer className={cm(styles.footer, styles.footerPhone)} style={{ marginTop: top }}>
      {/* <div className={styles.outer}>
          {contactArr.map((item, index) => {
            return (
              <a
                href={item.url}
                key={index}
                aria-label={item.aria}
                rel="nofollow"
                className={styles.img}
                target="_blank"
              >
                <Image initSrc="/api/images/footer/call1" src={item.img} alt="" />
              </a>
            )
          })}
        </div> */}

      <div className={styles.footTop1}>
        <div className={styles.infoTitle}>最佳体验，尽在 华视界 APP</div>
      </div>

      <div className={styles.footTop}>
        <div className={styles.inner}>
          <Button link hover="opacity" href="/" className={styles.btn}>
            <Image
              initSrc="/api/images/footer/call1"
              src="/api/images/footer/web"
              alt="web"
              className={cm(styles.btnImg, styles.webImg)}
            />
            <span>网页端</span>
          </Button>

          <Button
            link
            hover="opacity"
            href="https://static.ymedium.top/app/tv.apk"
            className={cm(styles.btn, styles.btnOne)}>
            <Image
              initSrc="/api/images/footer/call1"
              src="/api/images/footer/tv"
              alt="电视"
              className={styles.btnImg}
            />
            <span>T V 下载</span>
          </Button>
          <Button link hover="opacity" href="https://static.ymedium.top/app/phone.apk" className={styles.btn}>
            <Image
              initSrc="/api/images/footer/call1"
              src="/api/images/footer/android"
              alt="手机"
              className={styles.btnImg}
            />
            <span>安卓下载</span>
          </Button>
        </div>
      </div>

      {/* <div className={styles.footTop1}>
        <div className={styles.infoTitle}>让每一次娱乐体验都纯粹而美好</div>
    </div> */}
      <div className={styles.footBottom}>
        <p>
          版权声明：如果来函说明本网站提供内容本人或法人版权所有。本网站在核实后，有权先行撤除，以保护版权拥有者的权益。
        </p>
        <p>邮箱地址：hlovetv@outlook.com</p>
        <p>Copyright 2024 hlove.tv All rights Reserved.</p>
      </div>
      {/* <div className={styles.footBottom2}>
        <p>我们使用Cookies来改善您的使用体验。如您继续使用我们的网站，即表示你同意我们使用Cookies。</p>
      </div> */}
    </footer>
  )
}

export default Index
